<template>
  <v-container>
    <v-card :loading="pageLoading" class="shadow-lg mx-12 pb-2">
      <div class="px-8 pt-4">
        <v-btn to="/app/reports" depressed>
          <v-icon class="mr-2">mdi-arrow-left</v-icon>
          Go Back to Reports
        </v-btn>
      </div>
      <v-card-title class="mx-4 mb-0 pb-0">
        <div>
          <h3 class="d-block mb-0">
            {{ report._id ? 'Edit' : 'Create' }} Report
          </h3>
        </div>
        <v-spacer></v-spacer>
      </v-card-title>
      <p class="ml-8 mb-4">
        Enter Document details for Common Standard Report (CRS).
      </p>

      <div v-if="!user.institution">
        <img
          src="../../../assets/img/access_denied.svg"
          alt=""
          class="mx-auto mb-4 mt-12"
          style="display: block; width: 170px !important"
        />
        <h1 class="text-center mb-4">
          Administrators are not allowed to
          {{ report._id ? 'edit' : 'create' }}
          {{ report.isTestData ? 'Test ' : '' }} reports. <br />
          <v-btn color="primary" class="mx-auto" to="/app/reports"
            >Go Back</v-btn
          >
        </h1>
      </div>

      <ValidationObserver v-else ref="reportObserver">
        <v-row class="px-12">
          <v-col cols="6">
            <v-checkbox
              :disabled="isUpdating"
              label="Test report"
              hint="Check this box if this is a test report"
              persistent-hint
              v-model="report.isTestData"
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
              :disabled="isUpdating"
              label="Domestic Report"
              hint="Check this box if this is a report to be submitted to you local tax authority."
              persistent-hint
              @change="onDomesticReportToggle"
              v-model="report.isDomestic"
            />
          </v-col>
          <v-col cols="12">
            <ValidationProvider
              name="message type"
              rules="required"
              events="['blur']"
              v-slot="{ errors }"
            >
              <v-select
                :items="messageTypeIndicators"
                :disabled="isUpdating"
                item-text="name"
                item-value="code"
                :error-messages="errors"
                v-model="report.messageTypeIndicator"
                autocomplete="null"
              >
                <template #label>
                  Message Type
                  <span class="red--text"><strong>* </strong></span>
                </template>
              </v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider
              name="Receiving Country"
              rules="required"
              events="['blur']"
              v-slot="{ errors }"
            >
              <v-select
                :items="countries"
                item-text="name"
                item-value="alphaISO2"
                :error-messages="errors"
                v-model="report.recipientCountry"
                @change="onRequiredFieldsChanged"
                :disabled="isUpdating || report.isDomestic"
                autocomplete="null"
              >
                <template #label>
                  Receiving Country
                  <span class="red--text"><strong>* </strong></span>
                </template>
              </v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" class="py-0">
            <ValidationProvider
              name="reporting period"
              :rules="`required|year_less_than:${thisYear}`"
              events="['blur']"
              v-slot="{ errors }"
            >
              <v-text-field
                type="date"
                @input="onRequiredFieldsChanged"
                v-model="report.reportingPeriodEndDate"
                :error-messages="errors"
                autocomplete="null"
              >
                <template #label>
                  Reporting Period End Date
                  <span class="red--text"><strong>*</strong></span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="6">
            <v-text-field
              background-color="grey lighten-5"
              label="Unique Message Identifier"
              readonly
              v-model="report.uniqueMessageIdentifier"
              autocomplete="null"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              background-color="grey lighten-5"
              readonly
              label="Unique Document Identifier"
              v-model="report.uniqueDocumentIdentifier"
              autocomplete="null"
            />
          </v-col>

          <v-col cols="12" class="py-0">
            <ValidationProvider
              name="additonal contact information"
              rules="max:3000"
              events="['blur']"
              v-slot="{ errors }"
            >
              <v-textarea
                rows="3"
                label="Additional Contact Information"
                placeholder="Eg. John Doe | +8760000000 | johndoe@email.com"
                :error-messages="errors"
                v-model="report.additionalContact"
                autocomplete="null"
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="12" class="py-0">
            <ValidationProvider
              name="warning"
              rules="max:4000"
              events="['blur']"
              v-slot="{ errors }"
            >
              <v-textarea
                label="Warning"
                placeholder=""
                :error-messages="errors"
                v-model="report.warning"
                autocomplete="null"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-card-actions class="my-4 mx-8">
          <v-spacer></v-spacer>
          <v-btn text @click="onBack"> Cancel </v-btn>
          <v-btn color="primary" @click="onSubmit"> Save </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>

    <!-- Modals -->
    <ConfirmationModal
      ref="activeToggleConfirmationModal"
      :title="confirmationTitle"
      subtitle=""
      :message="confirmationMessage"
      confirmColor="error darken-1"
    />
  </v-container>
</template>

<script>
import PageMixin from '@/mixins/page.mixin';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { isEqual, omit, cloneDeep } from 'lodash';
export default {
  name: 'LoanForm',
  mixins: [PageMixin],
  data: () => ({
    thisYear: new Date().getFullYear(),
    messageTypeIndicator: 'CRS701',
    pageLoading: true,
    report: {},
    oldReport: {},
    confirmationTitle: '',
    confirmationMessage: ''
  }),

  async mounted() {
    try {
      this.pageLoading = true;
      const { id } = this.$route.params;
      if (id) {
        this.report = await this.findReport(id);
        if (!this.report) {
          this.showSnack({
            color: 'warning',
            title: `Report not found`,
            message: `Unable to find report.`
          });
          this.$router.push('/app/reports');
          return;
        }

        this.oldReport = cloneDeep(this.report);
        return;
      }

      this.report = {
        documentType: 'OECD1'
      };
    } catch (error) {
      this.httpErrorHandler(error);
    } finally {
      this.pageLoading = false;
    }
  },

  computed: {
    ...mapGetters('coreModule', ['countries', 'messageTypes']),
    ...mapGetters('reportModule', ['reports']),

    messageTypeIndicators() {
      const isUpdate = this.report._id && this.report.exported ? true : false;
      return this.messageTypes.filter(
        (type) => type.code !== (isUpdate ? 'CRS701' : 'CRS702')
      );
    },

    isUpdating() {
      return this.report._id ? true : false;
    }
  },

  methods: {
    ...mapActions('reportModule', [
      'createReport',
      'updateReport',
      'findReport',
      'fetchReport',
      'deleteAccount',
      'generateUniqueReportID',
      'generateUniqueMessageID'
    ]),

    async onDomesticReportToggle() {
      if (this.report.isDomestic) {
        //generateID using company id as first
        const { institution } = this.user;
        this.report.recipientCountry = institution.country;
        this.report.uniqueMessageIdentifier =
          await this.generateUniqueMessageID({
            report: this.report,
            user: this.user
          });
      }
    },

    async onRequiredFieldsChanged() {
      this.report.uniqueMessageIdentifier = await this.generateUniqueMessageID({
        report: this.report,
        user: this.user
      });
      this.$forceUpdate();
      this.report.uniqueDocumentIdentifier = await this.generateUniqueReportID({
        report: this.report,
        user: this.user
      });
      this.$forceUpdate();
    },

    async onBack() {
      this.confirmationTitle = `You are about to cancel the ${
        this.report._id ? 'edits made to' : 'creation of'
      } this report.`;
      this.confirmationMessage = `Are you sure you want to carry out this action?`;

      return this.$refs.activeToggleConfirmationModal.show(async () => {
        this.$router.replace('/app/reports');
      });
    },

    isMessageHeaderNew() {
      const warningChanged =
        this.report?.warning?.trim() !== this.oldReport?.warning?.trim();

      const additionalContactChanged =
        this.report?.additionalContact?.trim() !==
        this.oldReport?.additionalContact?.trim();

      const reportingPeriodChanged =
        this.report?.reportingPeriodEndDate?.trim() !==
        this.oldReport?.reportingPeriodEndDate?.trim();

      return (
        warningChanged || additionalContactChanged || reportingPeriodChanged
      );
    },

    async modifyReportAsCorrection() {
      if (this.isMessageHeaderNew()) {
        this.messageTypeIndicator = 'CRS702';
        this.report.messageTypeIndicator = this.messageTypeIndicator;

        this.report.correctedMessageReferenceIdentifier =
          this.report.uniqueMessageIdentifier;

        this.report.uniqueMessageIdentifier =
          await this.generateUniqueMessageID({
            report: this.report,
            user: this.user
          });
      }

      //check if company information is different, if so set documentTypeIndicator to OECD2
      //use old document reference id to set corrected document reference identifier
      //then generate a new unique document identifier
      const ignoreKeys = [
        'isActive',
        '_id',
        'dateCreated',
        'dateUpdated',
        'defaultIndividualNameType',
        'defaultIndividualAddressType',
        'defaultInvidualAccountNumberType',
        'defaultOrganizationNameType',
        'defaultOrganizationAddressType',
        'defaultAccountHolderType',
        'defaultOrganizationAccountNumberType',
        'defaultControllingPersonType'
      ];

      const oldInstitution = omit(this.report.institution, ignoreKeys);
      const newInstitution = omit(this.user.institution, ignoreKeys);

      if (!isEqual(oldInstitution, newInstitution)) {
        this.report.correctedDocumentReferenceIdentifier =
          this.report.uniqueDocumentIdentifier;
        this.report.uniqueDocumentIdentifier =
          await this.generateUniqueReportID({
            report: this.report,
            user: this.user
          });
      }
    },

    async onSubmit() {
      const isUpdating = !!this.report._id;
      const isValid = await this.$refs.reportObserver.validate();
      if (!isValid) {
        return;
      }

      try {
        this.report.isTestData = this.report.isTestData || false;

        this.report.reportingPeriodEndDate = moment(
          this.report.reportingPeriodEndDate
        ).format('YYYY-MM-DD');
        this.report.dateCreated = moment().format();
        if (!isUpdating) {
          this.report.isActive = true;
          await this.createReport(this.report);
        } else {
          //check if report was already exported and find out if user wants to
          //save the current edits as correction to a previously submitted report.
          if (this.report.exported && this.isMessageHeaderNew()) {
            this.confirmationTitle = `Changes have been made to this report which was exported`;
            this.confirmationMessage = `Do you want to save these edits as a correction for a previously submitted report?`;

            return this.$refs.activeToggleConfirmationModal.show(
              async () => {
                await this.modifyReportAsCorrection();
                await this.updateReport({
                  id: this.report._id,
                  payload: this.report
                });
                this.$router.replace('/app/reports');
              },
              async () => {
                await this.updateReport({
                  id: this.report._id,
                  payload: this.report
                });
                this.$router.replace('/app/reports');
              }
            );
          }

          await this.updateReport({
            id: this.report._id,
            payload: this.report
          });
        }

        this.showSnack({
          color: 'success',
          title: `${isUpdating ? 'Updated' : 'Created'}`,
          message: `Report was successfully ${
            isUpdating ? 'updated' : 'created.'
          }.`
        });

        this.$router.push('/app/reports');
      } catch (error) {
        this.httpErrorHandler(error);
        throw error;
      }
    }
  }
};
</script>
<style scoped>
.required-v >>> .v-label::after {
  content: ' *' !important;
  color: red !important;
}
</style>